<template>
  <div id="exchange">
    <div :style="`min-height: ` + windowHeight" class="content"></div>
    <!-- <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230519154800028.png" class="exch-img1" /> -->
    <img src="http://freesaasofficedata.oss.aliyuncs.com/mishop/20231020182005766.png" class="exch-img1" />
    <div class="brand-list" v-if="activityState">
      <div class="brand-item" :class="{ 'is-active': brandId === item.brandId }" @click="onBrandChange(item.brandId)"
        v-for="(item,index) in brandList" :key="index">{{ item.brandName }}</div>
    </div>
    <div class="vouchers">
      <div style="text-align: start" class="tit">我的兑换券<span>兑换券不支持找零</span></div>
      <ul v-if="vouchersList && vouchersList.length > 0">
        <li v-for="(item, index) in vouchersList" :class="{ 'active': item.checked }" :key="index">
          <div class="select">
            <div class="desc1">
              <!--<img src="../../img/fjxActivity/exch_img2.png"/>-->
              <span>兑换券</span>
            </div>
            <div class="van-checkbox" @click="changeVouchers(item)">
              <div class="van-checkbox__icon van-checkbox__icon--round"
                :class="{ 'van-checkbox__icon--checked': item.checked }">
                <i class="van-icon van-icon-success"></i>
              </div>
            </div>
          </div>
          <div class="price">
            <div class="item_money"><span style="font-size: 10px">¥</span>{{ item.money }}</div>
            <!-- <div class="item_money_box">
              <div>订单满额：¥{{ item.amountDetails.mjeAmount }}</div>
              <div>明星单品：¥{{ item.amountDetails.mxdpAmount }}</div>
            </div> -->
          </div>
          <div class="source ellipsis1" @click="jumpOrder(item)">来源订单：{{ item.sourceOrderNum }}</div>
        </li>
      </ul>
      <div class="no-vou" v-else>暂无兑换券~</div>
      <div class="attention"><van-icon name="warning" size="16" />兑换商品随兑换券的来源订单一起包邮发货,因包裹装箱及运输原因,不同来源订单的兑换券不支持一起使用。</div>
    </div>
    <div class="main top">
      <p class="tit1">选择兑换商品</p>
      <ul class="goods-list">
        <li v-for="item in productList" :key="item.good_id">
          <div @click="getProductById(item.good_id)">
            <div class="shop-img">
              <img class="mui-media-object" :src="item.good_urls">
              <img v-if="item.selloutImg" :src="item.selloutImg" class="selloutImg" alt="">
            </div>
            <div class="mui-media-body">
              <p style="text-align: start" class="ellipsis2 name">{{ item.good_name }}</p>
              <p style="text-align: start" class="ml-num ellipsis1">{{ item.specifications }}
                <span class="storeroom" v-if="brandId === 4">{{ item.deliveryMethod === 1 ? '国内发货' : '澳洲直邮' }}</span>
              </p>
              <p style="text-align: start" class="prod-txt ellipsis1">{{ item.actEffect }}</p>
              <p style="text-align: start" class="price">
                <span>￥</span>{{ item.good_price }}<em></em>
              </p>
            </div>
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210223111548911.png" class="shop_bg">
          </div>
          <van-stepper v-model="item.CartCount" class="buy-box" :disabled="item.stockNum <= 0" :disable-input="true"
            @plus="numberPlus(item, $event)" @overlimit="handleUnClickBtn(item)" min="0" @change="numberChange(item)"
            :class="{ 'hide-minus-btn': item.CartCount <= 0 }"></van-stepper>
        </li>
      </ul>
    </div>
    <div class="footer" :class="{ 'lay': showOrderModal }" @click="showOrderModal = 0">
      <div class="order-data" v-if="showOrderModal" @click.stop="showOrderModal = 1">
        <div class="clear">
          已选商品
          <div @click.stop="clearOrderList">
            <span class="mui-icon icon-ssa" style="font-size: 20px;color: #aaa"></span>
            清空
          </div>
        </div>
        <div class="list-cont" v-if="currentList.length > 0">
          <template v-for="(item, index) in currentList">
            <div class="list" v-if="item.CartCount > 0" :key="index">
              <div class="tit1 ellipsis1">{{ item.good_name }}</div>
              <div class="pri-num">
                <p>￥<span>{{ item.good_price }}</span></p>
                <van-stepper v-model="item.CartCount" class="buy-box my-box" disable-input="true" min="-1"
                  @change="numberChange(item)"></van-stepper>
              </div>
            </div>
          </template>
        </div>
      </div>
      <nav class="mui-bar mui-bar-tab" v-if="cartBoxInfo">
        <div class="cart_logo_box">
          <div class="img_box">
            <!-- <img class="cart_logo_img1" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211026164151668.png" alt=""> -->
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210401142052024.png" class="cart_logo_img2"
              alt="">
          </div>
          <div class="cart_num_box">{{ cartBoxInfo.allNum }}</div>
        </div>
        <div class="car-text">
          <div class="car-money"><s style="text-decoration: none">总价</s>￥{{ cartBoxInfo.allAmount }}</div>
          <p data-lan="TotalValue">已用兑换券￥{{ vouchersPrice }}
            <span v-if="cartBoxInfo.payAmount > 0">，需支付<span class="pink">￥{{ cartBoxInfo.payAmount }}</span></span>
          </p>
        </div>
        <button type="button" class="mui-btn settlement" @click.stop="goSettlement">去结算</button>
      </nav>
    </div>
    <div class="AnimaToCart" ref="animalToCart"></div>
    <div class="layer" v-if="isShow">
      <div class="hint-cont">
        <div class="title1">提示</div>
        <span class="close" @click="isShow = 0">×</span>
        <p>本次使用兑换券金额<span>{{ vouchersPrice }}元</span>，您选择兑换的商品总价为<span>{{ cartBoxInfo.allAmount }}元</span>。</p>
        <p class="pad-top">兑换券不支持找零，可以补差</p>
        <div class="btn">
          <span @click="sureExchange">确认兑换</span>
          <span class="active" @click="isShow = 0">再看看</span>
        </div>
      </div>
    </div>

    <div v-if="notPay" class="overlay">
      <div class="container">
        <div class="title">提示</div>
        <div class="content_words">您已有一张兑换券兑换的商品未完成支付，请先支付！</div>
        <div @click="routerChange" class="look_btn">去查看兑换订单</div>
      </div>
    </div>
    <toolbar></toolbar>
  </div>
</template>

<script>
import { Subtr, accMul, getQueryValue } from "../../../utils/handle";
import toolbar from "../../../components/toolbar";

export default {
  name: "index",
  components: { toolbar },
  data() {
    return {
      brandList: [],
      windowHeight: '',
      productList: [],
      currentList: [],
      addToCartBtn: null, //添加到购物车的小图标
      cartBoxInfo: {
        allAmount: 0,
        allNum: 0,
        payAmount: 0
      }, // 购物车数据
      vouchersPrice: 0,
      showOrderModal: 0,
      vouchersList: [],//优惠券列表
      orderNum: getQueryValue("orderNum") || "",//订单编号 用来选中优惠券
      settlementGoods: null,
      voucherObj: null,
      isShow: 0, // 模态框
      notPay: false,
      agentOrderId: '',
      brandId: 3,
      activityState: null
    }
  },
  created() {
    const { brandId } = this.$route.query;
    if (brandId) this.brandId = +brandId;
    this.$nextTick(function () {
      this.addToCartBtn = this.$refs.animalToCart;
    });
    document.title = '兑换专区'
    this.windowHeight = document.documentElement.scrollHeight + 'px'
    this.getExchangeBtnState();
  },
  methods: {
    onBrandChange(bid) {
      if (bid === this.brandId) return;
      this.brandId = bid;
      this.vouchersList = [];
      this.productList = [];
      this.initPageData();
    },
    getExchangeBtnState() {
      this.get("/OrderGoods/ActivityOther/GetActivityStateV2", {}, 2).then(json => {
        if (json.code === 1) {
          let data = json.response;
          let list = [];
          this.activityState = data;
          if (data.isExchangeMz) {
            list.push({ brandId: 3, brandName: "美妆" })
          }
          if (data.isExchangeDjk) {
            list.push({ brandId: 4, brandName: "大健康" })
          }
          if (!list.length) {
            this.$dialog.alert({
              title: "提示",
              message: "兑换专区暂未开启!",
            }).then(() => {
              this.$router.push("/")
            })
          } else {
            let bid = this.brandId;
            if (!list.find(it => it.brandId === bid)) {
              this.brandId = list[0].brandId;
            }
            this.brandList = list;
            this.initPageData();
          }
        }
      })
    },
    //初始化页面数据
    initPageData() {
      this.getVouchersList()
      this.getProductList();
      this.getOrderNoPay()
    },
    routerChange() {
      window.location.href = this.userHost + "/rolorweb/html/recharge/orderDetails.html?orderId=" + this.agentOrderId + '&type=1'
    },
    //检测有没有未支付的兑换订单
    getOrderNoPay() {
      this.get('/OrderGoods/ActivityOther/GetSubtotalUnPayV2', {
        source: 2,
        brandId: this.brandId
      }, 2).then(json => {
        if (json && json.code === 1) {
          var data = json.response;
          if (data.isBox) {
            let thenFun = () => {
              window.location.href = this.userHost + "/rolorweb/html/recharge/orderDetails.html?orderId=" + data.agentOrderId + '&type=1'
            }
            let catchFun = () => {
              this.brandId = this.brandId === 3 ? 4 : 3;
              this.initPageData();
            }
            if (this.brandList.length > 1) {
              this.$dialog.confirm({
                title: "提示",
                message: data.msgInfo,
                confirmButtonText: "去查看兑换订单",
                confirmButtonColor: "#333",
                cancelButtonText: `兑换${this.brandId === 3 ? '大健康' : '美妆'}商品`,
                cancelButtonColor: "#FF98A0"
              }).then(thenFun).catch(catchFun)
            } else {
              this.$dialog.alert({
                title: "提示",
                message: data.msgInfo,
                confirmButtonText: "去查看兑换订单",
                confirmButtonColor: "#FF98A0",
              }).then(thenFun)
            }
          }
        }
      })
    },

    jumpOrder: function (item) {
      window.location.href = this.userHost + '/rolorweb/html/recharge/orderDetails.html?orderId=' + item.sourceOrderId + '&type=1'
    },
    //获取兑换券列表
    getVouchersList() {
      var that = this
      this.get('/OrderGoods/ActivityOther/GetExchangeListV2', {
        source: 2,
        brandId: this.brandId
      }, 2).then(json => {
        if (json.code == 1) {
          var array = json.response || [], price = 0;
          for (var i = 0; i < array.length; i++) {
            array[i].checked = false;
            if (array[i].sourceOrderNum == that.orderNum) {
              array[i].checked = true;
              price = Subtr(price, -array[i].money);
              that.vouchersPrice = price;
              var temp = array[i];
              array.splice(i, 1);
              array.unshift(temp);
              break
            }
          }
          that.vouchersList = array;
        }
      })
    },
    //获取商品列表
    getProductList() {
      this.$toast.loading({ message: '加载中...', duration: 0, forbidClick: true })
      this.get('/OrderGoods/ActivityOther/GetGoodListV2', {
        source: 2,
        brandId: this.brandId
      }, 2).then(json => {
        this.$toast.clear();
        if (json && json.code === 1) {
          var cartListStr = window.localStorage.cartListStr;
          var zoneList = cartListStr ? JSON.parse(cartListStr) : [];
          var array = json.response;
          for (var i = 0; i < array.length; i++) {
            array[i].CartCount = 0;
            if (array[i].stockNum > 0) {
              for (var n = 0; n < zoneList.length; n++) {
                if (zoneList[n].goodId === array[i].good_id) {
                  array[i].CartCount = zoneList[n].CartCount
                }
              }
            }
          }
          this.productList = array
          this.calculatePrice()
        }
      })
    },
    changeVouchers(item) {
      var list = this.vouchersList, price = 0;
      for (var i = 0; i < list.length; i++) {
        if (list[i].id === item.id) {
          list[i].checked = !list[i].checked;
        } else {
          list[i].checked = false;
        }
      }
      for (var m = 0; m < list.length; m++) {
        if (list[m].checked) {
          price = Subtr(price, -list[m].money)
        }
      }
      this.vouchersPrice = price;
      this.vouchersList = list;
      this.calculatePrice();
    },
    //计算价格
    calculatePrice() {
      var allAmount = 0, allNum = 0, payAmount = 0;//总金额、总数量、支付金额
      var list = this.productList || [], array = [];
      for (var i = 0; i < list.length; i++) {
        var d = list[i];
        if (d.CartCount > 0) {
          allAmount = Subtr(allAmount, -accMul(d.good_price, d.CartCount));
          allNum = Subtr(allNum, -d.CartCount);
          array.push({ goodId: list[i].good_id, CartCount: list[i].CartCount })
        }
      }
      payAmount = Subtr(allAmount, this.vouchersPrice);
      var data = {
        allAmount: allAmount,
        allNum: allNum,
        payAmount: payAmount > 0 ? payAmount : 0
      };
      window.localStorage.cartListStr = JSON.stringify(array);
      this.cartBoxInfo = data;
    },
    //商品数量变化
    numberChange: function (item) {
      this.calculatePrice();
    },
    //点击+按钮
    numberPlus: function (product, e) {
      // console.log(product, e)
      var that = this;
      // var X = e.clientX - e.target.offsetWidth;
      // var Y = e.clientY + e.target.offsetHeight / 2;
      // this.addToCartBtn.style.left = X + "px";
      // this.addToCartBtn.style.bottom = (window.innerHeight - Y) + "px";
      // setTimeout(function () {
      //     addClass(that.addToCartBtn, "moveToBottom");
      // }, 10);
      // setTimeout(function () {
      //     that.addToCartBtn.style.bottom = "-1px";
      //     that.addToCartBtn.style.left = "-1px";
      //     removeClass(that.addToCartBtn, "moveToBottom");
      // }, 500);
    },
    sureExchange: function () {
      var that = this;
      var coupon = this.voucherObj;
      var params = {
        exchangeId: coupon.id,
        goodList: JSON.stringify(this.settlementGoods)
      };
      this.isRepSub++;
      if (this.isRepSub > 1) {
        return false
      } else {
        this.$toast.loading({ message: '提交中...', duration: 0, overlay: true });
        that.post('/OrderGoods/ActivityOther/CreateOrderSubtotalV2', params, true, 2).then(json => {
          that.$toast.clear();
          if (json.code === 1) {
            var data = json.response;
            that.initPage();
            window.location.href = this.userHost + "/rolorweb/html/recharge/orderDetails.html?orderId=" + data.agentOrderId + '&type=1'
          } else {
            that.isRepSub = 0;
            that.isShow = 0;
            that.$dialog.alert({ title: '提示', message: json.msg });
          }
        })
      }
    },
    initPage: function (id) {
      this.settlementGoods = null;
      this.voucherObj = null;
      this.isShow = false;
      this.isRepSub = 0;
      this.currentList = [];
      var list = this.vouchersList;
      for (var k = 0; k < list.length; k++) {
        if (list.id === id) {
          list.splice(k, 1);
        }
      }
      var proList = this.productList || [];
      for (var i = 0; i < proList.length; i++) {
        proList[i].CartCount = 0;
      }
      window.localStorage.cartListStr = "";
    },
    //去结算
    goSettlement() {
      var that = this;
      this.showOrderModal = 0;
      var list = this.productList || [], array = [], obj = null;
      var vList = this.vouchersList || [];
      for (var i = 0; i < list.length; i++) {
        var good = list[i];
        if (good.CartCount) {
          array.push({
            good_id: good.good_id,
            good_count: good.CartCount,
            good_price: good.good_price
          });
        }
      }
      if (!array.length) return this.$toast('请选择结算商品');
      for (var m = 0; m < vList.length; m++) {
        if (vList[m].checked) {
          obj = vList[m]
        }
      }
      if (!obj) {
        if (this.vouchersList.length > 0) return this.$toast('请选择兑换券');
        else return this.$dialog.confirm({
          title: '提示',
          message: '您暂未获得兑换券，无法兑换商品',
          confirmButtonText: '再看看',
          cancelButtonText: '去订货'
        }).then(function () {

        }).catch(function () {
          that.getUserIdentityNewData()
        });
      }
      this.settlementGoods = array;
      this.voucherObj = obj;
      this.isShow = 1
    },
    getUserIdentityNewData() {
      this.post('/TeamAgent/AgentInfo/HomeIdentity_V2').then(json => {
        if (json && json.code == 1) {
          var list = json.response;
          var level = Math.max.apply(Math, list.map(function (o) {
            return o.homelevel
          }));
          if (level > 2) {
            //跳代理专区groupon_list
            window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html?brandId=1";
          } else {
            //跳团购
            window.location.href = this.baseHost + "/7999/Arongleweb/html/eliteAgent/good_list.html?brandId=1";
          }
        }
      })
    },
    handleUnClickBtn: function (item) {
      if (item.stockNum <= 0) {
        this.$toast(item.good_name + '产品库存告急，停止兑换')
      }
    }
  }
}
</script>

<style lang="scss">
#exchange {
  overflow: hidden;

  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;

    .container {
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      padding: 15px;
      border-radius: 15px;
      box-sizing: border-box;
      width: 92%;

      .title {
        color: #666;
        text-align: center;
        font-size: 16px;
      }

      .content_words {
        color: #999;
        margin-top: 15px;
        font-size: 14px;
        max-width: 80%;
        text-align: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }

      .look_btn {
        width: 85%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-color: #E9A24C;
        color: #fff;
        text-align: center;
        height: 35px;
        line-height: 35px;
        margin-top: 30px;
        border-radius: 550px;
      }
    }
  }

  .AnimaToCart {
    position: fixed;
    bottom: -12px;
    left: -12px;
    width: 12px;
    height: 12px;
    z-index: 99;
    color: #fff;
    text-align: center;
    background-color: #ffa8c6;
    border-radius: 50%;
  }

  .van-stepper {
    input {
      background-color: transparent;
    }
  }

  .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #E9A24C;
    border-color: #E9A24C;
  }

  .layer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 245;

    .hint-cont {
      width: 260px;
      height: 200px;
      background: #fff;
      position: absolute;
      left: calc(50% - 130px);
      top: calc(50% - 90px);
      border-radius: 6px;
      text-align: center;

      .title1 {
        font-size: 16px;
        color: #666;
        padding: 15px 0;
      }

      .close {
        font-size: 16px;
        color: #666;
        width: 20px;
        height: 20px;
        background: #f1f1f1;
        border-radius: 50%;
        margin: 10px;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        line-height: 20px;
      }

      p {
        padding: 0 25px;
        font-size: 12px;
        color: #999;

        &.pad-top {
          padding-top: 5px;
        }

        span {
          color: #FF9F25;
        }
      }

      .btn {
        margin-top: 15px;

        span {
          font-size: 14px;
          color: #666666;
          border: 1px solid #e5e5e5;
          width: 40%;
          display: inline-block;
          border-radius: 30px;
          height: 31px;
          line-height: 31px;

          &.active {
            background: #E9A24C;
            color: #fff;
            border-color: #E9A24C;
            margin-left: 5%;
          }
        }
      }
    }
  }

  .content {
    position: fixed;
    z-index: -2;
    width: 100%;
    background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230519154830197.png") no-repeat;
    background-size: 100% 100%;
  }

  .exch-img1 {
    width: 100%;
    // margin-top: 10px;
  }

  .brand-list {
    margin: 0 15px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 6px 6px 0 0;
    background-color: #fff;
    border-bottom: 1px solid #F4F4F4;

    .brand-item {
      flex: 1;

      padding: 17px 0;
      font-weight: bold;
      font-size: 14px;
      color: #fff;
      background-color: #FFB5BB;

      &.is-active {
        color: #FFB5BB;
        background-color: #fff;
      }
    }
  }

  .vouchers {
    background: #fff;
    margin: 0 15px;
    border-radius: 0 0 6px 6px;
    padding: 10px 0 20px;
    overflow: hidden;

    .tit {
      font-size: 14px;
      color: #666;
      padding: 0 15px;

      em {
        font-size: 12px;
        color: #999;
        font-style: normal;
      }

      span {
        font-size: 12px;
        color: #FF9F25;
        float: right;
      }
    }

    .no-vou {
      font-size: 12px;
      color: #999;
      margin: 10px 15px;
      border: 1px dashed #ccc;
      text-align: center;
      line-height: 70px;
      border-radius: 6px;
    }

    ul {
      white-space: nowrap;
      text-align: start;
      overflow-x: auto;
      padding: 10px 15px;

      li {
        border: 1px solid #E9A24C;
        width: 170px;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 10px;
        overflow: hidden;

        &.active {
          background: #fffbe2;
        }

        &:last-child {
          margin-right: 0;
        }

        .select {
          padding: 6px 8px 0;
          background-color: rgb(255, 251, 226);

          .desc1 {
            display: inline-block;
            padding-left: 5px;
            background: #E9A24C;
            border-radius: 30px;
            width: 50px;
            height: 15px;
            box-sizing: border-box;
            position: relative;
            line-height: 24px;

            img {
              width: 22px;
              height: 12px;
              position: relative;
              top: 1px;
            }

            span {
              font-size: 10px;
              color: #fff;
              padding: 0 5px;
              border-radius: 0 30px 30px 0;
              line-height: 13px;
              display: inline-block;
              position: absolute;
              right: 1px;
              top: 1px;
            }
          }

          .van-checkbox {
            float: right;
          }
        }

        .price {
          font-size: 22px;
          color: #E9A24C;
          width: 100%;
          padding-left: 5px;
          box-sizing: border-box;
          padding-right: 1px;
          /*padding: 0 8px;*/
          display: flex;
          justify-content: space-between;
          line-height: 29px;
          background-color: #FFF4DB;

          .item_money {
            font-size: 24px;
          }

          .item_money_box {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .source {
          font-size: 10px;
          color: #E9A24C;
          padding: 1px 12px 1px 8px;
          background: #fff;
          border-top: 1px dashed #E9A24C;
          border-radius: 0 0 6px 6px;
          position: relative;

          &:before {
            content: "";
            width: 5px;
            height: 5px;
            position: absolute;
            right: 7px;
            top: 7px;
            border-top: 1px solid #ff9f25;
            border-right: 1px solid #ff9f25;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
        }
      }
    }

    .attention {
      text-align: start;
      font-size: 12px;
      color: #E81E2A;
      background: #FBF0DC;
      padding: 10px;
      border-radius: 6px;
      margin: 0 10px;

      span {
        background: #E9A24C;
        font-size: 10px;
        color: #fff;
        border-radius: 30px;
        padding: 0 4px;
        margin-right: 3px;
      }
    }
  }

  .main {
    margin: 0 15px 0;
    background: #fff;
    border-radius: 8px;
    border-top: 1px dashed #908bff;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -7.5px;
      left: -7.5px;
      width: 15px;
      height: 15px;
      background: #F1DAB1;
      border-radius: 50%;
    }

    &:after {
      content: "";
      position: absolute;
      top: -7.5px;
      right: -7.5px;
      width: 15px;
      height: 15px;
      background: #F4E4CA;
      border-radius: 50%;
    }

    .tit1 {
      font-size: 14px;
      text-align: start;
      color: #666;
      padding: 20px 15px 10px;
    }

    .goods-list {
      padding-bottom: 50px;

      li {
        position: relative;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .shop-img {
          position: relative;
          width: 110px;
          height: 110px;
          display: inline-block;
          padding: 10px;
          box-sizing: border-box;

          img {
            width: 100%;
          }

          .selloutImg {
            position: absolute;
            width: 100%;
            transform: scale(0.4);
            left: 0;
            top: 0;
            opacity: 0.8;
          }
        }

        .shop_bg {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .mui-media-body {
          //padding: 10px;
          line-height: 20px;
          width: calc(100% - 120px);
          display: inline-block;
          vertical-align: top;
          position: relative;
          z-index: 12;
          text-align: left;

          p {
            margin-bottom: 0;

            &.name {
              font-size: 14px;
              font-weight: 600;
              color: #636363;
            }

            &.ml-num {
              font-size: 12px;
              color: #636363;
            }

            &.prod-txt {
              font-size: 12px;
              color: #b5b5b5;
            }

            &.price {
              font-size: 18px;
              color: #FF9F25;
              padding-top: 10px;

              span {
                font-size: 14px;
              }

              em {
                font-size: 12px;
                color: #b5b5b5;
                font-style: normal;
                padding-left: 5px;
              }

              s {
                font-size: 12px;
                color: #fff;
                background: #8830cc;
                border-radius: 20px;
                padding: 1px 13px;
                float: right;
              }
            }
          }

          .storeroom {
            color: #ffb5bb;
            font-size: 9px;
            display: inline-block;
            padding: 2px 10px 2px 5px;
            margin-left: 10px;
            line-height: 12px;
            position: relative;
            border: 1px solid #ff98a1;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              border-top: 8px solid transparent;
              border-right: 8px solid #ff98a1;
              border-bottom: 8px solid transparent;
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              right: -1px;
              border-top: 8px solid transparent;
              border-right: 8px solid #fff;
              border-bottom: 8px solid transparent;
            }
          }
        }

        .buy-box {
          position: absolute;
          right: 0;
          bottom: 20px;
          width: 100px;
          border: 0;
          padding: 0;
          z-index: 99;
          background-color: inherit;
          -webkit-transition: all 1s ease-out;
          -moz-transition: all 1s ease-out;

          input {
            margin: 0;
            width: 79%;
            color: #666;
            border: none;
            padding: 0 22px;
            height: 30px;
            box-sizing: border-box;
            display: inline-block;
            -webkit-text-fill-color: #666;
            -webkit-opacity: 1;
            position: relative;
            top: 5px;
          }

          button {
            position: absolute;
            z-index: 6;
            padding: 0;
            height: 100%;
            border: none;
            font-size: 22px;
            background-color: #fff0;

            &:before {
              top: 4px;
              width: auto;
              height: auto;
              background-color: transparent;
              font-family: rolormd !important;
            }
          }

          .van-stepper__plus {
            right: 0;
            color: #E9A24C;

            &:before {
              content: "\e96c";
              position: relative;
              left: -9px;
              top: 5px;
            }

            &:after {
              width: auto;
              height: auto;
              background-color: transparent;
            }

            &.van-stepper__plus--disabled {
              opacity: 0.6;
            }
          }

          .van-stepper__minus {
            color: #aaa;

            &:before {
              content: "\ea10";
              position: relative;
              top: 6px;
              left: 0;
            }

            &.van-stepper__minus--disabled {
              opacity: 0.6;
            }
          }

          &.hide-minus-btn {
            .van-stepper__minus {
              opacity: 0;
            }

            .van-stepper__input {
              opacity: 0;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }

  .footer {
    &.lay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 240;
    }

    .order-data {
      background: #fff;
      z-index: 12;
      position: absolute;
      width: 100%;
      bottom: 51px;
      max-height: 60%;
      left: 0;
      right: 0;
      overflow-y: auto;

      .hint {
        font-size: 12px;
        color: #333;
        background: #ffe8e8;
        padding: 6px 0;
        text-align: center;
      }

      .clear {
        font-size: 18px;
        color: #999;
        padding: 10px 15px;

        div {
          font-size: 12px;
          color: #999;
          display: inline-block;
          float: right;

          span {
            position: relative;
            top: 2px;
          }
        }
      }

      .list-cont {
        height: calc(100% - 130px);

        .list {
          padding: 5px 15px;
          position: relative;
          font-size: 0;

          &:last-child {
            padding-bottom: 30px;
          }

          .tit1 {
            font-size: 12px;
            color: #333;
            width: 50%;
            display: inline-block;
            position: relative;
            top: 5px;
          }

          .pri-num {
            width: 50%;
            display: inline-block;
            text-align: right;
            position: relative;

            p {
              display: inline-block;
              font-size: 12px;
              color: #8830cc;
              padding-right: 5px;

              span {
                font-size: 16px;
              }
            }

            .my-box {
              display: inline-block;
              width: 100px;
              border: 0;
              padding: 0;
              z-index: 8;
              background-color: inherit;
              -webkit-transition: all 1s ease-out;
              -moz-transition: all 1s ease-out;
              vertical-align: text-bottom;

              input {
                margin: 0;
                width: 100%;
                color: #666;
                border: none;
                padding: 0 22px;
                height: 30px;
                box-sizing: border-box;
                display: inline-block;
                -webkit-text-fill-color: #666;
                -webkit-opacity: 1;
                position: relative;
                top: 6px;
              }

              .van-stepper__input[disabled] {
                background: transparent !important;
              }

              button {
                position: absolute;
                z-index: 6;
                padding: 0;
                height: 100%;
                border: none;
                font-size: 22px;
                background-color: #fff0;

                &:before {
                  top: 4px;
                  width: auto;
                  height: auto;
                  background-color: transparent;
                  font-family: rolormd !important;
                }
              }

              .van-stepper__minus {
                color: #aaa;

                &:before {
                  content: "\ea10";
                }

                &.van-stepper__minus--disabled {
                  opacity: 0.6;
                }
              }

              &.hide-minus-btn {
                .van-stepper__minus {
                  opacity: 0;
                }

                .van-stepper__input {
                  opacity: 0;
                  background-color: transparent !important;
                }
              }
            }
          }
        }
      }
    }

    .mui-bar-tab {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      height: 50px;
      background-color: #fff;
      border-top: 1px solid #f1f1f1;

      .car-but {
        position: absolute;
        top: -9px;
        left: 15px;
        z-index: 996;
        color: #fff;
        width: 48px;
        height: 48px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        background-color: #E9A24C;

        .box {
          border-radius: 50%;
          border: 3px solid #fff;
        }

        [class^="icon-"] {
          font-size: 22px;
          line-height: 44px;
        }

        .mui-badge {
          position: absolute;
          top: -5px;
          right: -8px;
          color: #E9A24C;
          font-size: 12px;
          line-height: 1;
          padding: 3px 6px;
          border-radius: 100px;
          display: inline-block;
          border: 1px solid #E9A24C;
          background-color: #fff;
        }
      }

      .cart_logo_box {
        position: absolute;
        left: 5px;
        bottom: 12px;
        display: flex;
        width: 50px;
        height: 50px;

        .img_box {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: linear-gradient(to bottom, #FD914D, #FD914D);
          background: -webkit-linear-gradient(to bottom, #FD914D, #FD914D);
        }

        .cart_logo_img1 {
          width: 50px;
          height: 50px;
        }

        .cart_logo_img2 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 23px;
          height: 23px;
        }

        .cart_num_box {
          position: absolute;
          z-index: 1;
          right: -4px;
          top: 0;
          background-color: #fff;
          border: 1px solid #E9A24C;
          border-radius: 10px;
          padding: 0px 3px;
          color: #E9A24C;
        }
      }

      .car-text {
        position: absolute;
        bottom: 9px;
        left: 58px;
        color: #999;
        font-size: 12px;

        .car-money {
          color: #FF9F25;
          text-align: start;
          font-size: 16px;

          s {
            color: #666;
            font-size: 14px;
            font-weight: bold;
          }
        }

        .pink {
          color: #E81E2A;
        }
      }

      .mui-btn {
        font-size: 14px;
        position: absolute;
        top: 8px;
        right: 10px;
        color: #fff;
        border: none;
        padding: 7px 23px;
        letter-spacing: 2px;
        border-radius: 33px;
        background-color: #E9A24C;
      }
    }

    &.not-allowed {
      .mui-bar-tab {
        .mui-btn {
          background-color: #666;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .content .head .vouchers .tit {
    font-size: 12px;
  }

  .content .head .vouchers .tit em {
    font-size: 10px;
  }

  .content .head .vouchers .tit span {
    font-size: 10px;
  }
}

@media screen and (max-width: 330px) {
  .footer .order-data .list-cont .list .pri-num .my-box {
    width: 92px;
  }

  .footer .order-data .list-cont .list .pri-num p {
    font-size: 10px;
  }
}
</style>